@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i);
body {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  color: #333; }

h1 {
  color: #4b77be;
  font-size: 1.8rem; }

strong {
  color: #2b4978; }

.publicacoes__estatisticas {
  padding: 16px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 10px; }
  .publicacoes__estatisticas p {
    margin: 0; }
    .publicacoes__estatisticas p span {
      display: block; }
    .publicacoes__estatisticas p strong {
      display: block;
      text-align: right;
      font-size: 30px;
      color: #4b77be; }
    .publicacoes__estatisticas p small {
      font-size: 20px; }

.publicacoes__nivel-similaridade {
  height: 10px;
  margin: 10px 0 30px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden; }
  .publicacoes__nivel-similaridade div {
    transition: all linear 1s;
    background: #26a65b;
    height: 10px; }

.publicacoes__list-item {
  margin-bottom: 10px; }
  .publicacoes__list-item .metadados {
    padding-top: 10px; }
    .publicacoes__list-item .metadados li {
      font-size: 85%; }
  .publicacoes__list-item__header {
    background: rgba(75, 119, 190, 0.15);
    border-bottom: 1px solid #4b77be;
    padding: 10px;
    margin-bottom: 0;
    cursor: pointer; }
    .publicacoes__list-item__header .toggle-content {
      float: right;
      font-size: 20px;
      margin-right: 10px;
      color: #4b77be; }
  .publicacoes__list-item__diff {
    padding: 10px; }

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .loader__spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top-color: #4b77be;
    border-radius: 50%;
    -webkit-animation: spin 1s infinite;
            animation: spin 1s infinite; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.card-header-tribunal {
  cursor: pointer; }

.table .acoes {
  white-space: nowrap; }

.card table thead tr th {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  background: white;
  outline: 1px solid #ccc;
  border: none;
  z-index: 1; }

.card table thead tr:last-child th {
  top: 105px; }

.card table .btn.active {
  z-index: 0; }

