@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i");
$primary: #4b77be;

body {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  // padding: 20px;
  color: #333;
}

h1 {
  color: $primary;
  font-size: 1.8rem;
}
strong {
  color: darken($primary, 20);
}
.publicacoes {
  &__estatisticas {
    padding: 16px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-radius: 10px;
    p {
      margin: 0;
      span {
        display: block;
      }
      strong {
        display: block;
        text-align: right;
        font-size: 30px;
        color: $primary;
      }
      small {
        font-size: 20px;
      }
    }
  }
  &__nivel-similaridade {
    height: 10px;
    margin: 10px 0 30px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    div {
      transition: all linear 1s;
      background: #26a65b;
      height: 10px;
    }
  }
  &__list {
    &-item {
      margin-bottom: 10px;
      .metadados {
        padding-top: 10px;
        li {
          font-size: 85%;
        }
      }
      &__header {
        background: transparentize($color: $primary, $amount: 0.85);
        border-bottom: 1px solid $primary;
        padding: 10px;
        margin-bottom: 0;
        cursor: pointer;
        .toggle-content {
          float: right;
          font-size: 20px;
          margin-right: 10px;
          color: $primary;
        }
      }
      &__diff {
        padding: 10px;
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #ccc;
    border-top-color: $primary;
    border-radius: 50%;
    animation: spin 1s infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-header-tribunal {
  cursor: pointer;
}

.table .acoes {
  white-space: nowrap;
}

.card table thead tr th {
  position: sticky;
  top: 60px;
  background: white;
  outline: 1px solid #ccc;
  border: none;
  z-index: 1;
}

.card table thead tr:last-child th {
  top: 105px;
}

.card table .btn.active {
  z-index: 0;
}
